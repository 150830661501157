var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CbDrawer",
        {
          attrs: {
            title: "添加制度",
            size: "normal",
            "z-index": 4,
            "confirm-text": "发布",
            closable: false,
          },
          on: { close: _vm.onClose, onSubmit: _vm.onSubmit },
          scopedSlots: _vm._u([
            {
              key: "cenFooter",
              fn: function () {
                return [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "default" },
                      on: { click: _vm.toPreview },
                    },
                    [_vm._v("预览")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "a-form-model",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "制度标题", prop: "institutionTitle" } },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入名称2-30个字符",
                          "max-length": 30,
                        },
                        model: {
                          value: _vm.form.institutionTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "institutionTitle", $$v)
                          },
                          expression: "form.institutionTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "接收范围", prop: "staffList" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择接收范围" },
                          on: { change: _vm.onChange },
                          model: {
                            value: _vm.form.scope,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "scope", $$v)
                            },
                            expression: "form.scope",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: "" + item.value,
                              attrs: { value: item.value },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                      _vm.form.scope === "choose"
                        ? _c("CbSelectPersons", {
                            attrs: { "data-echo": _vm.perDataList },
                            on: {
                              perModelConfirm: _vm.perModelConfirm,
                              delUser: _vm.delUser,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "制度内容", prop: "institutionText" } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          placeholder: "请输入制度内容",
                          rows: 15,
                          spellcheck: false,
                        },
                        model: {
                          value: _vm.form.institutionText,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "institutionText", $$v)
                          },
                          expression: "form.institutionText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "附件", prop: "fileId" } },
                    [
                      _c("UploadFile", {
                        attrs: {
                          "type-list": [
                            "pdf",
                            "xlsx",
                            "docx",
                            "doc",
                            "ppt",
                            "pptx",
                            "txt",
                            "xls",
                          ],
                          "file-size": 20,
                          "max-sum": 5,
                          "upload-description":
                            "文件格式支持 PDF/XLSX/DOC/DOCX/PPT/PPTX/TXT/XLS，大小在20MB内",
                          "show-upload-list": "",
                        },
                        on: {
                          fileUpload: _vm.fileUpload,
                          onDelete: _vm.onDelete,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("Preview", { ref: "preview" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }